import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PjTranslateService } from './pjtranslare.service';

// Not used. keept for ref
@Pipe({
  name: 'translateHtml',
  pure: false
})
export class PjtranslateHmlPipe implements PipeTransform {
  constructor(public translate: PjTranslateService,
    private sanitizer: DomSanitizer    
    ) {

  }
  transform(key: string, ...args: unknown[]): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.translate.get(key));
  }

}



@Pipe({
  name: 'translate'
})
export class PjtranslatePipe implements PipeTransform {
  constructor(public translate: PjTranslateService
    ) {

  }
  transform(key: string, ...args: unknown[]): string {
    return this.translate.get(key);
  }

}
