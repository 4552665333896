import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BizUserDto } from 'src/app/api-lib/client/api-entities';
import { ProfileApi } from 'src/app/api-lib/client/profile-api';
import { SessionStorage } from 'src/app/api-lib/session-storage';
import { PjalertService } from 'src/app/sharedlib/pjalert.service';
import { PjTranslateService } from 'src/app/sharedlib/pjtranslare.service';

@Component({
  selector: 'app-home-nav',
  templateUrl: './home-nav.component.html',
  styleUrls: ['./home-nav.component.scss']
})
export class HomeNavComponent implements OnInit {

  public queryParams: Params = {}

  public profileData?: BizUserDto 

  public campaignIds: Array<any> = []
  public profileImage?: string
  public isAdmin: boolean = false
  public brandName?: string
  public expandMenu: boolean = false
  private expandClickListener?: any
  
  constructor(private route:ActivatedRoute, 
    private translator: PjTranslateService,
    private profileApi: ProfileApi,
    private sessionStorage: SessionStorage,
    private pjAlert: PjalertService
    ) {
      this.isAdmin = sessionStorage.isAdmin()
   }

   get canNavigate():boolean{
    return !(this.campaignIds.length > 1 && (typeof this.campaignIds) !== 'string')
   }

  ngOnInit(): void {

    this.expandClickListener = (e:any) => {
      this.expandMenu = false
      document.querySelector('.main-container')?.removeEventListener('click',this.expandClickListener)
      console.log("expandClickListener:")

    }

    let userId = this.sessionStorage.getUserId()

    this.brandName = this.sessionStorage.getBrandName()

    this.route.queryParams.subscribe((p:any) =>{

      this.campaignIds = p.cid ?? []
      this.queryParams = p

    })
    if(!this.isAdmin){
      this.profileApi.getProfile(userId).subscribe((res) => {
        this.profileData = res
        this.profileImage = res.profileImage
        if(res.brandId){
          this.sessionStorage.setBrandid(res.brandId)
          this.sessionStorage.setBrandName(res.brandName)
        }
       
      })
      
    }



  }
  doExpandMenu(){
    this.expandMenu = !this.expandMenu

    document.querySelector('.main-container')?.removeEventListener('click',this.expandClickListener)
    
    if(this.expandMenu){
      document.querySelector(".main-container")?.addEventListener('click', this.expandClickListener)
    }else{
      document.querySelector('.main-container')?.removeEventListener('click',this.expandClickListener)
    }
  }

  candidatesClickDisabled(){

    this.pjAlert.show( {message: this.translator.get('select_one_campaign')} )
    


  }

}
