import { join } from '@angular-devkit/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs';

import { PerformApi } from 'src/app/api-lib/client/perform-api';
import { ProfileApi } from 'src/app/api-lib/client/profile-api';
import { SessionStorage } from 'src/app/api-lib/session-storage';
import { LangManagerService } from 'src/app/language-switch/lang-manager.service';

@Component({
  selector: 'app-pdf-loader',
  templateUrl: './pdf-loader.component.html',
  styleUrls: ['./pdf-loader.component.scss']
})
export class PdfLoaderComponent implements OnInit{


  private campaignIds: Array<any> = []

  constructor(private dialogRef: MatDialogRef<PdfLoaderComponent>,  
    public sessionStorage: SessionStorage,
    public apiProfile: ProfileApi,
    public langManager: LangManagerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private performApi: PerformApi ){
      this.campaignIds = data.campaignIds
  }

  ngOnInit(): void {


    let brandId = this.sessionStorage.getBrandId()

    this.performApi.downloadPdf(this.campaignIds,brandId,this.langManager.getCurrentLanguage())
    
    .pipe(map((res) => new Blob([res], { type: 'application/pdf' })))

    .subscribe((res) => {

      this.apiProfile.getBrandInfo(brandId).subscribe((brand) => {
        var blob = res;
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.genFilename(brand.name)
        link.click();
  
        this.dialogRef.close()
      })

    })
  }

  private genFilename(brandName: string){
    let currrentDate = new Date()
   let dateFrmt = "_"+ currrentDate.toISOString().split('T')[0].replace(/\-+/g,'')

    let prefix = "PJdaren_"
    let end =  dateFrmt
    brandName = brandName.trim().replace(/\s+/g, ' ')

    let name =  prefix +  brandName +  end + ".pdf"

    return name
  }

}

