import {  NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StyleUtilService } from './style-util.service';
import { ApiUtilService } from './api-util.service';
import { OsTypeDirective } from './os-type.directive';
import { PjtranslateHmlPipe, PjtranslatePipe } from './pjtranslate.pipe';
import { PlatformModule } from '@angular/cdk/platform';
import { PjstringUtilService } from './pjstring-util.service';
import { PjSettingsService } from './pj-settings.service';
import { CommaNumberPipe } from './comma-number.pipe';
import { PjimgurlPipe } from './pjimgurl.pipe';
import { KnumformatPipe } from './knumformat.pipe';
import { EmptyDataComponent } from './empty-data/empty-data.component';
import { PjloaderComponent } from './pjloader/pjloader.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PjloaderService } from './pjloader.service';
import { MatDialogModule } from '@angular/material/dialog';
import { IntersectionObserverDirective } from './intersection-observer/intersection-observer.directive';
import { PjalertDiaolgComponent } from './pjalert-diaolg/pjalert-diaolg.component';
import { LangTypeDirective } from './lang-type.directive';
import { TruncatorPipe } from './truncator.pipe';

@NgModule({
  exports:[
    KnumformatPipe,
    PjimgurlPipe,
    OsTypeDirective,
    PjtranslatePipe,
    PjtranslateHmlPipe,
    CommaNumberPipe,
    EmptyDataComponent,
    LangTypeDirective,
    IntersectionObserverDirective,
    TruncatorPipe
  ],
  declarations: [
    PjimgurlPipe,
    OsTypeDirective,
    PjtranslatePipe,
    PjtranslateHmlPipe,
    CommaNumberPipe,
    KnumformatPipe,
    EmptyDataComponent,
    PjloaderComponent,
    IntersectionObserverDirective,
    PjalertDiaolgComponent,
    LangTypeDirective,
    TruncatorPipe
  ],
  providers: [
    StyleUtilService,
    ApiUtilService,
    PjstringUtilService,
    PjSettingsService,
    PjloaderService
  ],
  imports: [
    PlatformModule,
    CommonModule,
    MatProgressBarModule,
    MatDialogModule
  ]
})
export class SharedlibModule { }
