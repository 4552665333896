export const translateEn = {
    "signup": "Sign Up",
    "signin": "Sign in",

    "required_field" : "This field is required",
    "create_account": "Create an account",
    "first_name" : "First Name",
    "last_name" : "Last Name",
    "job_title" : "Job Title",
    "brand_agency_name" : "Brand or Agency Name",
    "working_email" : "Working email",
    "phone_number": "Phone Number",
    "how_hear" : "Where did you hear about us?",
    "accept_receive_news" : "I consent to receive newsletters and other communications from 评价达人 - PJdaren.",

    "active_campaigns" : "Active Campaigns",
    "inactive_campaigns" : "Inactive Campaigns",
    "refresh": "Refresh",
    "members": "members",
    "download_pdf_v2": "Download PDF",
    "download_candidates" : "Download",
    "engage_by_platform" : "Engagement by Platform",
    "total_engagment": "Total Engagement",

    "total_creators": "Participating Creators",
    "creators" : "Creators",
    "total_investment" : "Total Investment",
    "posts": "Posts",
    "creators_by_platform" : "Creators by Platform",
    "posts_by_platform" : "Posts by Platform",
    "overall_performance" : "Overall Performance",
    "total_reach" :"Total Reach",
    "total_engagement" :"Total Engagement",
    "rech_by_platform" : "Reach by Platform",
    "mpv_chart" : "MPV",
    "roi_chart" : "ROI",
    "daily_performance":"Daily Performance",
  "post_by_day" : "Post By Day",
  "enage_by_day" : "Engagement by Day",
  "reach_by_day" : "Reach by Day",
  "performance_data" : "Performance Data",
  "platform_perfomance" : "Platform Performance",

  "unknown_server_error" : "Unknown server error",
  "registration_completed" : "Your registration request has been submitted!",
  "invalid_login" :"Wrong email or password.",
  "accept_privacy_policy" : "Please accept privacy policy.",
  "thank_register" : "Thank you for registering an account with PJdaren!",


// candidates tab
  "candidates": "Candidates",
  "profile_url" : "Profile Url",
  "estimated_eng" : "Estimated Engagement",
  "estimated_reach" : "Estimated Reach",
  "suggested_creators" : "Suggested Creators",
  "approved_creators" : "Approved",
  "rejected_creators" : "Rejected",
  "achieved" : "Achieved:",
  "target" : "Target:",
  "select_one_campaign" : "Please, select a single campaign to access to this section.",

  "suggested_candiates" : "Suggested Candidates",
  "checked_candiates" : "Checked Candidates",
  "pending_candiates" : "Pending Candidates",
  "approved_candidates" : "Approved Candidates",
  "rejected_candidates" : "Rejected Candidates",
  "enlarge_qr_code" : "Click on QR Code to enlarge",

  "followers" : "Followers",
  "total_eng" : "Total Engagement",
  "categories" : "Categories",
  "act_approve" : "Approve",
  "act_reject" : "Reject",
  // End candidates tab

  // Performance Data


  "data_platform" : "Platform",
  "number_creators" : "# Creators",
  "post" : "Post",
  "engagement":"Eng.",
  "engage_rate" : "Eng.%",
  "mpv" : "MPV",
"investment" : "Investment",
"roi": "ROI",
"downlaod_links_platform": "Download",
"creators_perform": "Creators Performance",

"creator_id": "Creator\’s ID",
"followers_number": "# Followers",
"data_er": "ER",
"data_rp": "RP",
  "data_rp_excel": "REACH PERFORMANCE (%)",
"data_eng" : "ENG.",
"data_ee" : "EE",
  "data_ee_excel" :"ESTIMATED ENGAGEMENT",
"data_ep" : "EP",
  "data_ep_excel" : "ENGAGEMENT PERFORMANCE (%)",
"data_eng_percent" : "Eng. %",
  "data_eng_excel" : "ENGAGEMENT RATE (%)",
"resonance" : "Resonance",
"resonance_excel" : "Resonance (%)",
"likes_number" : "Likes",
"favorites_number" : "Favorites",
"collections_number" : "Collection",
"repost_number" : "Reposts",
"comments_number" : "Comments",
"shares_number" : "Shares",
"nickname" : "Nickname",
"link" : "Link",
"perfomance_overview" : "Performance Overview",
"post_detail": "Post Details",
"read_more" : "...Read More",
"hide" : "...Hide",
"小红书": "Xiaohongshu",
"抖音": "Douyin",
"微博": "Weibo",
"微信": "Wechat",
"淘宝": "Taobao",
"customize_mpv" : "Customize",
"customize_media_value" : "Customize Media Impact Value",
"action_save" : "Save",
"sns_platform" : "Platform",
"sns_reposts" : "Reposts",
"sns_collections" : "Collections",
"defaul_sns_rates" : "Default Social Impact Rates Matrix",
// info signs
"total_eng_info"  : "Sum of likes, comments, collections and shares/repost of social media posts.",
"total_reach_info" : " Sum of all reads or views of social media posts.",
"mpv_info" : `Media Performance Value (MPV) measures the cost-effectiveness of influencer campaigns based on the cost of paid social media ads.`,
"roi_info" : `(MPV - Investment) / Investment. A measure of influencer marketing campaign efficiency in terms of advertising value vs investment.`,
"eng_rate_info" : "(Total Engagements / Total Reach) * 100. A measure of audience interaction with social media posts.",
"estimated_reach_info" : `A projection of the number times a social media post will be read or watched.
based on past performance data.`,

"estimated_eng_info" : `A projection of audience interactions with a social media post, based on past performance data.`,
"reach_perfomance_info" : `(Actual Reach / Estimated Reach) * 100. A measure of how well a social media post’s reach performed compared to its estimated reach.`,
"eng_performance_info" : ` (Actual Eng. / Estimated Eng.) * 100. A measure of how well a social media post’s engagement performed compared to its estimated engagement.`,
"resonance_info" : `(Total Views / Total Followers) * 100. A measure of the impact a social media post has compared to the creator's following.`,
"creator_platform_info" : "Number of content creators who have started preparing content per social media platform.",
"post_by_platform_info" : "Number contents that have been approved and published per social media platform.",
// my profile;

"m_name" : "Name",
"perosnal_detail":  "Personal Details",
"position_" : "Position",
"wechatId": "WeChat ID*",
"optional" : "(optional)",
"action_cancel" : "CANCEL",
"up_save" : "SAVE",
"change_pwd" : "Change Password",
"current_pwd" : "Current Password",
"input_cur_pwd" : "Input Current Password",
"new_pwd" : "New Password",
"input_new_pwd" : "Input New Password",
"confirm_pwd" : "Confirm Password",
"no_data" : "There is no data",
"PHONE_IS_TAKEN" : "The phone is already taken.",
"EMAIL_IS_TAKEN" : "The email is already taken.",



  // End Performance Data


  // end content sample



  // start question insights

  "total_respondents" : "Total Respondents:",
  "show_as" : "Show As",
  "rating_label": "Rating",



  // end question insights

    "dont_have_account": "Don't have an account yet?",
      "my_campaign": "My Campaigns",
      "campaigns": "My Campaigns",
      "my_profile": "My Profile",
      "logout": "Logout",
      "login": "Login",
    "email_id": "Email address",
      "email": "Email",
    "username": "username",
    "password": "Password",
      "old_password": "Input old password",
      "new_password": "Input new password",
      "confirm_password": "Confirm new password",
    "confirm_modification":"Modify",
    "remember_me": "Remember me",
    "forgot": "Forgot Password?",
    "brand_name": "Brand Name",
      "contact_name": "My name",
      "phonenumber": "Phone number",
    "register": "Register",
      "male": "Male",
      "female": "Female",
    "campaign_list": "Campaign List",
      "upload_image": "Update your brand image",
      "brand_information": "My Brand Information",
      "change_brand_information": "Update your contact information",
      "change_password": "Change Password",
    "input": "Input",
      "confirm": "Confirm",
    "determine":"Okay",
    "answers": "Answers",
      "percentage": "Percentage",
      "count": "Count",
    "load": "load",
    "load_feedback": "load feedback..",
    "load_more": "load more..",
      "total_unique_respondents": "Total Unique Respondents",
      "user_profile": "Participant Profile",
      "performance": "Campaign Performance",
      "sample_content": "Sample Content",
      "insight": "Insights",
      "total_numbers": "Participating Members",
      "avg_income": "Average Monthly Household Income Level",
      "avg_income1": "Average Monthly Household Income",
      "age_distribution": "Age Distribution",
      "sex_distribution": "Sex Distribution",
      "areal_distribution": "Geographical Distribution",
      "city_tier_distribution": "City tier Distribution",
      "income_distribution": "Income Distribution",
      "social_media_app_users": "Social Media Usage",
      "interest_distribution": "Interest Distribution",
      "amount_of_action": "Actions",
    "interactive_quantity": "Total Interactives",
    "challenges": "Challenges",
      "exposure": "Reach",
      "interactions": "Interactions",
      "est_eng" : "Estimated Engagement",
      "est_reach" : "Estimated Reach",
      "total_eng_short": "Total Eng.",
      "est_eng_short" : "Est. Eng.",
      "est_reach_short" : "Est. Reach",
      "reach": "Reach",
      "user_engagement": "Reponse rate",
      "aggregated_daily_campaign_performance": "Aggregated Daily Campaign Performance",
    "aggregated_daily_challenges":"Aggregated daily challenges",
    "aggregated_daily_interaction":"Aggregated daily interaction",
    "aggregated_daily_reach":"Aggregated daily reach",
    "aggregated_daily_campaign_performance1":"Aggregated Daily Campaign Performance - Reach",
    "aggregated_daily_campaign_performance2":"Aggregated Daily Campaign Performance - Interactions",
    "aggregated_daily_campaign_performance3":"Aggregated Daily Campaign Performance - Actions",
      "daily_campaign_performance_variation": "Daily Campaign Performance Variation",
      "aggregate_product_reviews": "Aggregated Product Reviews on PJdaren",
      "aggregate_weekly_campaign_badge_holders": "Aggregated Weekly Campaign Badge Holders",
      "daily_traffic": "Daily Online traffic to Flagship Store",


    "above_44": "14",
    "from_17_to_24": "11",
    "from_25_to_34": "12",
    "from_35_to_44": "13",
    "11":"17 to 24",
    "12":"25 to 34",
    "13":"35 to 44",
    "14":"Above 44",
    "income_316": "Below ¥3,999",
    "income_321": "¥4,000 - ¥7,999",
    "income_326": "¥8,000 - ¥11,999",
    "income_331": "¥12,000 - ¥15,999",
    "income_336": "¥16,000 - ¥19,999",
    "income_341": "¥20,000 - ¥24,999",
    "income_346": "Above ¥25,000",
      "32": "Fitness",
      "37": "Students",
      "35": "Moms",
      "38": "Party",
      "41": "Pets",
      "30": "Beauty",
      "36": "Wellness",
      "34": "Fashion",
      "42": "Cooking",
      "39": "Snacks",
    "t1": "T1",
    "t2": "T2",
    "t3": "T3",
    "t4": "T4",
    "t5": "T5",
    "t6": "T6",
    "t7": "T7",
    "t8": "T8",
    "t9": "T9",
    "t10": "T10",
    "old": "Old",
    "new": "New",
    "cn": "中文",
    "en": "English",
    "ko": "中文ko",
    "du": "fæêë",
    "lang": "en cn",
    "cancel": "Cancel",
    "do_you_want_to_logout": "Do you want to logout?",
    "send": "send",
    "send_email_id": "Send Email Address",
      "download_pdf": "Download as PDF",
      "download_csv": "Download as CSV",
      "download_png": "Download Image as PNG",
      "download_jpg": "Download Image as JPG",
      "CN-11": "Beijing",
      "CN-13": "Hebei",
      "CN-14": "Shanxi",
      "CN-15": "Nei Mongol",
      "CN-21": "Liaoning",
      "CN-22": "Jilin",
      "CN-23": "Heilongjiang",
      "CN-31": "Shanghai",
      "CN-32": "Jiangsu",
      "CN-34": "Anhui",
      "CN-35": "Fujian",
      "CN-36": "Jiangxi",
      "CN-37": "Shandong",
      "CN-41": "Henan",
      "CN-42": "Hubei",
      "CN-43": "Hunan",
      "CN-44": "Guangdong",
      "CN-45": "Guangxi",
      "CN-46": "Hainan",
      "CN-50": "Chongqing",
      "CN-51": "Sichuan",
      "CN-52": "Guizhou",
      "CN-53": "Yunnan",
      "CN-54": "Xizang (Tibet)",
      "CN-61": "Shaanxi",
      "CN-62": "Gansu",
      "CN-63": "Qinghai",
      "CN-64": "Ningxia",
      "CN-65": "Xinjiang",
      "CN-12": "Tianjin",
      "CN-71": "Taiwan",
      "CN-33": "Zhejiang",
      "search_brand": "Search Brand",
      "not_found": "Not Found",
      "week": "Week",
      "total_badge_owner": "Badge owner",
      "product_review": "Product reviews",
      "single_choice": "Single Choice Question",
      "multiple_choice" : "Multiple Choice Question",
      "ranking" : "Ranking Question",
      "mean" : "Mean",
      "rating" : "Rating Question",
      "intensity" : "Intensity Question",
      "open_text" : "Open Text Question",
      "numeric_input" : "Numeric Input Question",

    "info-content0": "Aggregated number of impressions/views generated by submitted challenges. ",
    "info-content1": "Aggregated number of interactions (Likes, Comments, Shares and Favorites) generated by submitted challenges.",
    "info-content2": "Total number of challenges successfully completed by participating members",
    "info-content3": "Aggregated reach by day",
    "info-content4": "Aggregated interactions by day",
    "info-content5": "Aggregated actions by day ",
    "info-content6": "Action, Interactions and Reach by day",
    "info-content7": "Number of reviews on PJdaren app by day",
    "info-content8": "Number of participating members who have completed all brand challenges by week.",
    "info-content9": "Percentage of participating members who have submitted at least 1 challenges. ",
    "disclaimer": "Note: The data displayed in this section is updated in real time. All data is verified by PJdaren's proprietary algorithm before being reflected on campaign performance metrics and graphs.",
    "footer-note": "评价达人 | Powered by Real Consumers",
    "na": "N/A",

"please_select" : "Please select",


    // Pending Translate

      //  contents sample page
   "nav_content_sample": "Content",
   "sort_by" : "Sort by:",
   "sort_all" : "All",
   "sort_eng": "Engagement",

    // login page

    "already_have_account": "Already have an account?",
    //  Heard from

    "Baidu" : "Baidu",
    "Google" : "Google",
    "Linkedin" : "Linkedin",
    "Wechat" : "Wechat",
    "recommended_by_friend" : "Recommendation by a friend or colleague",
    "pub_blog" : "Publication or Blog",
    "person_event" : "In-Person Event",
    "Other" : "Other",

  // Reset Pwd
  "reset_pwd" : "Reset Password",
  "input_email_reset_pwd" : `Enter the email associated with your account and we'll send instructions to reset your password`,
  "ver_code" : "Email Verification Code",
  "passwords_not_match" : "The password you entered doesn't match.",

  // Candidate Reject dialog
  "help_understand_creators" : "Help us better understand your target creators!",
  "feedback_important" : "Your feedback is important to us. Could you please share your reason for rejecting this candidate?",
  "skip_btn" : "Skip",
  "submit_btn" : "Submit",
  "cancel_btn" : "Cancel",

  "edit_feedback" : `Add/edit feedback`,
  "edit_feedback_info" : `Help us better understand your target creators. Tell us why you rejected this candidate.`,

  // Alert dialog

  "ok_btn" : "Ok",
  "failed_upload_img" : 'Failed to upload image',
  "login_exapired" : "Session expired. Please login again.",

  "USER_NOT_FOUND" : "This user doesn't exist.",
  "EMAIL_IS_INVALID" : "The email you have entered is invalid.",
  "VERIFICATION_CODE_STILL_VALID" : "The verification code has been sent. Please try again in 1 min.",
  "VERIFICATION_CODE_INVALID" : "The verification code is wrong.",
  "VERIFICATION_CODE_EXPIRED" : "The verification code you have entered has expired.",

  // By creating this account you accept our Terms of Use and Privacy Policy
  "accept_pjdaren_privacy" : "By creating this account you accept our  ",
  "privacy_policy" : "Privacy Policy",
  "and" : "and",
  "terms_of_use" : "Terms of use",

  "candidate_price" : "Price",
  "price_yuan" : "Price(¥)",

  "team_get_back": "Our team is reviewing your request and will get back to you within 2 business days.",
  "contact_sales" : `If you’d like to expedite the process, please feel free to contact us at <a class='signup link bold' href='mailto:sales@pjdaren.com'>sales@pjdaren.com</a>`,
  "check_resource_center" : `In the meantime, check out our <a class="link bold" href="#">Resource Centre</a>`,

  // Pending translate

  "excel_post_date" : "Post date",
  "excel_angle": "ANGLE",
  "excel_content" : "content",
  "cand_remarks": "Remarks",
  "cand_feedback" : "Feedback",
  "cand_gender" : "Gender"



  }
