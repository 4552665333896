import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { AuthManager } from 'src/app/api-lib/auth-manager';
import { SessionStorage } from 'src/app/api-lib/session-storage';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss']
})
export class ProfileDropdownComponent implements OnInit{
  private queryParams: Params = {}
  constructor(private router: Router, 
    
    private authManager: AuthManager,
    private route: ActivatedRoute,
    private sessionStorage: SessionStorage){
  
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((p:any) =>{


      this.queryParams = p

    })
    
  }

  navProfile(){

    this.router.navigate(['./stats/profile'],{queryParams: this.queryParams })
  }

  doLogout(){
    this.authManager.doLogout()
  }

}
