import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PjloaderComponent } from './pjloader/pjloader.component';


// TODO: implment options
interface PjLoaderOptions{
  disableClose?: boolean,
  enableTimeout?: boolean,
  timeout?: number 
}
@Injectable({
  providedIn: 'root'
})
export class PjloaderService {


  constructor( public dialog: MatDialog ) { 
  }

  private dialoagRef?: MatDialogRef<PjloaderComponent>

 public show(options: PjLoaderOptions){

    let opt = {
      disableClose: (options.disableClose !== undefined) ?  options.disableClose : true
    }
    this.dialoagRef = this.dialog.open(PjloaderComponent, {
      width: '350px',
      height: '40px',
      panelClass: 'pdf-loader-dialog',
      disableClose: opt.disableClose
    });
    
  }
  public hide(){
    if(this.dialoagRef){
      this.dialoagRef.close()
    }
  
  }

}
