import { Component } from '@angular/core';

@Component({
  selector: 'app-pjloader',
  templateUrl: './pjloader.component.html',
  styleUrls: ['./pjloader.component.scss']
})
export class PjloaderComponent {

  
}
