import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StyleUtilService {

  constructor() { 

  }

  

 public hexToRgb(hexColor: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  public getGradientStyle(startColor: string, endColor: string, degree: number = 180){


    let rgbStart:any = this.hexToRgb(startColor)
    let rgbEnd:any = this.hexToRgb(endColor)
   

    return  `linear-gradient(${degree}deg, rgba(${rgbStart.r},${rgbStart.g},${rgbStart.b},1) 0%, rgba(${rgbEnd.r},${rgbEnd.g},${rgbEnd.b},1)100%);`

  }

 public getfireFoxScale(){
  return 0.8;
 } 

//https://stackoverflow.com/a/28222246/1163224
 getOffset(el:HTMLElement) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}


  
}
