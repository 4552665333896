<div class="home-container">
    <div class="side-bar-container" mosType >
        <app-campaigns-sidebar #sideBar></app-campaigns-sidebar>
    </div>

    <div  class="outer-part" mosType>
        <ng-container *ngIf="mainScriptsloaded">
            <app-campaign-stats-page></app-campaign-stats-page>
        </ng-container>

    </div>
</div>