import { Injectable } from '@angular/core';


import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class PjSettingsService {

  private _currency: string = "¥"
  private _tosUrl: string = "https://zh.pjdaren.com/terms"
  private _privacyUrl: string = "https://zh.pjdaren.com/privacy"
  constructor() { 

  }
  public get getCurrency():string{
    return this._currency
  }
  public set curency(value: string){
    this._currency = value
  }

  public get tosUrl(){
    return this._tosUrl
  }
  public get privacyUrl(){
    return this._privacyUrl
  }
  public getPdfPrinter(){
    return environment.pdfPrinter.concat("printpdf")
  }
  public getApiBase(){
    return environment.backendUrl
  }
  public getCollectorApi(){
    return environment.contentCollector
  }
  public getEnv(){
    return environment
  }
}
