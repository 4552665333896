import { Component, OnInit } from '@angular/core';
import { LazyLoadScriptService } from 'src/app/sharedlib/lazy-load-script.service';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {


  public sidebarWidth: number = 0

  public mainScriptsloaded: boolean  = false

  constructor(
    private lazyLoader: LazyLoadScriptService
  ) { }

  ngOnInit(): void {

    forkJoin({
      "jquery" :     this.lazyLoader.loadScript("./assets/js/jquery.js"),
      "jsh-ui.js" :  this.lazyLoader.loadScript("./assets/js/jsh-ui.js"),
      "jszip.min.js" : this.lazyLoader.loadScript("./assets/js/jszip.min.js"),
    }).subscribe((res) => {
      this.mainScriptsloaded = true
    })



  }

  ngAfterViewInit() {


   // this.router.navigate(['stats/members'])

  }
}
