import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUtilService {

  constructor() { 

  }


  public getImgUrl( imageName:string ){
    if (imageName == undefined) {
        imageName = "";
    }
    if(imageName.startsWith("http")){
      return imageName
    }
    return imageName ? environment.imgURL + imageName : '';
  }

  public putImage( imageName:string ){
    return this.getImgUrl(imageName)
  }
}
