import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { StyleUtilService } from './style-util.service';
import {Platform} from '@angular/cdk/platform';
import { PjplatformUtilService } from './pjplatform-util.service';
@Directive({
  selector: '[mosType]'
})
export class OsTypeDirective {

  constructor(private el: ElementRef, 
    private platform: Platform,
    private pjplatform: PjplatformUtilService,
    private renderer: Renderer2
    ) {
      renderer.addClass(el.nativeElement, "os-"+pjplatform.getOSString());

 
      if(this.platform.FIREFOX){
        renderer.addClass(el.nativeElement, "browser-firefox");
      }
      if(this.platform.EDGE){
        renderer.addClass(el.nativeElement, "browser-edge");
      }
      if(this.platform.BLINK){
        renderer.addClass(el.nativeElement, "browser-chromium");
      }
     
 }

}
