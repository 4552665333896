import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { PjalertDiaolgComponent, PjAlertParams } from './pjalert-diaolg/pjalert-diaolg.component';

@Injectable({
  providedIn: 'root'
})
export class PjalertService {

  constructor( public dialog: MatDialog ) { 
  }

  private dialoagRef?: MatDialogRef<PjalertDiaolgComponent>

  public show(options: PjAlertParams){

    this.dialoagRef = this.dialog.open(PjalertDiaolgComponent, {
      width: '350px',
    
      panelClass: 'post-detail-dialog',
      disableClose: true,
      data: options,
    });


    this.dialoagRef.afterClosed().subscribe(result => {

    });

  }
}
