import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatMenu} from '@angular/material/menu';
import {LangManagerService} from './lang-manager.service';
import {PjLangs} from "../sharedlib/pjtranslare.service";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit{


  @ViewChild("matMenu") matMenu!: MatMenu

  @Input()
  public theme: string = 'light'

  public langs = [{name: PjLangs.zhCn,title: '简体中文'},{name: PjLangs.enUs,title: 'English'}]

  public currentLang: string = ""


  constructor(public langManager: LangManagerService){

  }


  ngOnInit(): void {
    this.currentLang = this.langManager.getCurrentLanguage()
    if(this.matMenu){
      this.matMenu.panelClass = "language-switcher"
    }
    console.log(" this.currentLang", this.currentLang)
  }

  ngAfterViewInit() {

    // this returns null
  }

  switchLang(lang: string){

    this.langManager.setLanuage(lang)
  }

}
