import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PjstringUtilService {

  public domainReg?: RegExp

  public snsDict:any = {
    1 : "Weibo",
    3 : "Douyin",
    5 : "Xiaohongshu",
    2 : "Wechat",
    17 : "Taobao"
  }

  constructor() { 

    try{
      this.domainReg = new RegExp(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/,'im')
    }catch(e){
      console.error(e)
    }

  }

  public commaNumber(number: any){

    /*
     // alternative
let number = 1234567890;
let nf = new Intl.NumberFormat('en-US');
nf.format(number); //
    */
   let floor =  Math.floor(number)
    return floor.toLocaleString('en-US', {maximumFractionDigits: 0})
  }

//https://stackoverflow.com/a/55435856/1163224
  public *chunks<T>(arr:T[], n: number): Generator<T[], void> {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }


  //https://stackoverflow.com/a/4187164/1163224
public  roundWithoutLosing(digitst:string) {

   // var with2Decimals = digitst.match(/^-?\d+(?:\.\d{0,2})?/)![0]
   var with1Decimals = digitst.match(/^-?\d+(?:\.\d{0,1})?/)![0]
    return with1Decimals
}

 public combineDuplicateObject1(collection: any){
    let consolidate = [];
    let groupDuplicates = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push({name: x.realName.split(".")[0], value: x.value});
        return rv;
      }, {});
    };
    let groupedSearch = groupDuplicates(collection, 'name')
    for (let prop in groupedSearch ) {consolidate.push({name: prop, series: groupedSearch[prop]});}
    return consolidate;
  }

  public  combineDuplicateObject(collection:any){
    let consolidate = [];
    let groupDuplicates = function(xs:any, key:any) {
      return xs.reduce(function(rv:any, x:any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let groupedSearch = groupDuplicates(collection, 'subject')
    for (let prop in groupedSearch ) {consolidate.push([prop, groupedSearch[prop]]);}
    return consolidate;
  }


  //https://stackoverflow.com/a/9462382/1163224
 kFormatter(num:number, digits:number = 1) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }
  //https://stackoverflow.com/a/53637828/1163224
 public truncateString(str:string, num:number) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }
//https://stackoverflow.com/a/51506718/1163224
  public wrapString(s: string, w: number) {
    
    let str = s.replace(
      new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n');
  return str
  }

  // https://stackoverflow.com/a/14487422/1163224

  public wrapStringForGraph(str: string){
    
  }

  public getDomainName(url: string):string{


    if(!this.domainReg){
      return ""
    }

  let res =   this.domainReg.exec(url)
  let resUrl = ""

  if(res && res.length > 0){
    resUrl = res[1]
  }




    return resUrl

  }

  public formatFileDate(){
    let currrentDate = new Date()
    let dateFrmt = "_"+ currrentDate.toISOString().split('T')[0].replace(/\-+/g,'')

    return dateFrmt

  }

  public wrapStringV2(str: string, maxWidth: number) {
    var newLineStr = "\n"; 
    var done = false; 
    var res = '';
    var    found = false;
    var i = 0
    while (str.length > maxWidth) {                 
        found = false;
        // Inserts new line at first whitespace of the line
        for (i = maxWidth - 1; i >= 0; i--) {
            if (testWhite(str.charAt(i))) {
                res = res + [str.slice(0, i), newLineStr].join('');
                str = str.slice(i + 1);
                found = true;
                break;
            }
        }
        // Inserts new line at maxWidth position, the word is too long to wrap
        if (!found) {
            res += [str.slice(0, maxWidth), newLineStr].join('');
            str = str.slice(maxWidth);
        }

    }

    function testWhite(x:any) {
      var white = new RegExp(/^\s$/);
      return white.test(x.charAt(0));
  };

    return res + str;
  }

}
