import { Injectable } from '@angular/core';
import {PjLangs, PjTranslateService} from "../sharedlib/pjtranslare.service";

@Injectable({
  providedIn: 'root'
})
export class LangManagerService {

  constructor(private  pjTranslate: PjTranslateService) {

  }

  public setLanuage(lang: string){

    this.pjTranslate.setLang(lang)
    window.location.reload()
  }
  public getCurrentLanguage(){

   let lang = this.pjTranslate.getCurrentLang()

   return lang
  }
}
