import { Pipe, PipeTransform } from '@angular/core';
import { ApiUtilService } from './api-util.service';


@Pipe({
  name: 'pjimgurl'
})
export class PjimgurlPipe implements PipeTransform {

  constructor(private apiUtil: ApiUtilService){

  }

  transform(value: string, ...args: unknown[]): unknown {

    return  this.apiUtil.getImgUrl(value)

  }

}
