
<div class="menu-wrap"> 

    <img class="triangle-icon" src="./assets/icons/menu_triangle.png" /> 



    <div class="nav-menu d-flex w-100 flex-column" >
    
        <div (click)="navProfile()" class="nav-item">
            <img class="nav-icon" src="./assets/icons/profile_nav.png" />
            <span  class="section-label">{{'my_profile' | translate}}</span>
        </div>
    
        <div (click)="doLogout()" class="nav-item logout">
            <img  class="nav-icon" src="./assets/icons/profile_logout.png" />
            <span class="section-label " style="color: white;">{{'logout' | translate}}</span>
        </div>
    
    
    </div>
</div>
