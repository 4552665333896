<div   *ngIf="theme == 'light'"  class="language-switcher">
    <button class="switch-icon"  type="button" [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
        <img  class="language-icon" src="./assets/icons/earth-grid-symbol.png" />

    </button>
      <mat-menu   #menu="matMenu" [class]="'language-menu'">
        <button  *ngFor="let lang of langs" mat-menu-item (click)="switchLang(lang.name)">
          <img class="check-icon {{currentLang != lang.name ? 'hiden-icon' : '' }}"  src="./assets/icons/login-lang-tick.svg" />
          <span>{{lang.title}}</span>
        </button>
      </mat-menu>
</div>

<div  *ngIf="theme == 'dark'" class="language-switcher">
  <button class="switch-icon dark"  type="button" [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">

      <img  class="language-icon" src="./assets/icons/language_switch_dark.svg" />
  </button>
  
    <mat-menu   #menu="matMenu" [class]="'language-menu-dark'">
      <button  *ngFor="let lang of langs" mat-menu-item (click)="switchLang(lang.name)">
        <img class="check-icon {{currentLang != lang.name ? 'hiden-icon' : '' }}"  src="./assets/icons/language-toggle-light.svg" />
        <span>{{lang.title}}</span>
      </button>
    </mat-menu>

</div>


