import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PjSettingsService } from 'src/app/sharedlib/pj-settings.service';
import { Observable } from "rxjs";
import { PagedResponse, SnsEntity } from './api-entities';

export interface CandiateStatus{
  suggested: number,
  checked: number,
  pending: number,
  approved: number,
  rejected: number,
  approvalTarget: number,
  achievePercent: number
}


export interface BrandCandidatesCsv {
  id: number;
  nickname: string;
  profile_url: string;
  followers: number;
  total_engagement: number;
  estimated_eng: number;
  estimated_reach: number;
  gender: string;
  interests?: (string)[] | null;
  interestsStr?: string
}

export interface CandidateDto{

  id: any,
  userId: any
  nickname: string,
  remarks: string,
  gender: string,
  profileUrl: string,
  shortUrl?: string,
  followers: number,
  estEng: number,
  totalEng: number,
  estimatedReach: number,
  interests: Array<CandidateInterest>,
  checkStatus: string,
  verified:boolean,
  price: number
}
export interface CandidateInterest{
  id: any,
  key?: string,
  interestName: string
}


@Injectable({
  providedIn: 'root'
})
export class CandApi {


  constructor(private httpClient: HttpClient,
    private pjsettings: PjSettingsService){

    }


    public  getCandidatesList(snsId: any, campaignIds: Array<any>,
      checksTatus: string,
       pageSize: number = 12,pageNo: number  ):Observable<PagedResponse<CandidateDto>>{
      let url  = this.pjsettings.getApiBase().concat("brand/campaign/candidates")

      return this.httpClient.get(url,{params: {"campaigns": campaignIds, "snsId": snsId,
      checkStatus: checksTatus,pageNo: pageNo, pageSize: pageSize}}) as Observable<PagedResponse<CandidateDto>>
    }

    public approveReject(id: any, status: string){

      console.log("id", id)
      let url  = this.pjsettings.getApiBase().concat("brand/update-status/candidate")
      return this.httpClient.post(url,{
        id: id,
        status: status
      })
    }

    public addFeedback(id: any, feedback: string){

      console.log("id", id)
      let url  = this.pjsettings.getApiBase().concat("brand/feedback/candidate")
      return this.httpClient.post(url,{
        id: id,
        feedback: feedback
      })
    }


    public getFeedback(id: any){

      let url  = this.pjsettings.getApiBase().concat("brand/feedback/candidate/" + id)
      return this.httpClient.get(url)
    }

    public getCandidateSns(campaignIds: Array<any>):Observable<Array<SnsEntity>>{
      let url  = this.pjsettings.getApiBase().concat("brand/candidates/sns")

      return this.httpClient.get(url,{params: {"campaignIds": campaignIds}}) as Observable<Array<SnsEntity>>
  }

    public  getCandidateStats(snsId: any, campaignIds: Array<any> ):Observable<CandiateStatus>{


      let url  = this.pjsettings.getApiBase().concat("brand/campaign/candidatesStats")

      return this.httpClient.get(url,{params: {"campaigns": campaignIds, "snsId": snsId}}) as Observable<CandiateStatus>

    }

    public getCandidatestForExport(snsId: any, campaignIds: Array<any>,status: string): Observable<Array<BrandCandidatesCsv>>{
      let url  = this.pjsettings.getApiBase().concat("brand/brcandidates.json")
      return this.httpClient.get(url,{params: {"campaignIds": campaignIds, "snsId": snsId, status: status}}) as Observable<Array<BrandCandidatesCsv>>

    }


}
