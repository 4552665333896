<div class="campaigns-bar bar-bg"  mosType>

    <div class="logo-side">
        <a id="logo" class="{{logoLang}}"  href="/"></a>
   
    </div>


    <div class="camp-data-container {{activeCampaignExpand  || inactiveCampaignExpand ? 'expanded' : 'off'}}" mosType>


        <mat-accordion class="home-expand-mat">
            <mat-expansion-panel 
            [expanded]="activeCampaignExpand"
            (opened)="activeCampaignExpand = true" (closed)="activeCampaignExpand = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="panel-title" mosType>
                            {{ 'active_campaigns' | translate }}({{campaignList.length}})
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
    

                <div class="campaigns-list" mosType>
                    <ng-container *ngIf="campaignList.length > 0">
                        <div class="signup-btn-container">
                            <div class="refreshbtn-container" mosType>
                                <button (click)="applyRefresh()" class="signup-form-btn">
                                    <span class="global-font-type">{{ 'refresh' | translate }}</span>
                                    <img  src="./assets/icons/home_refresh_camp.svg"/>
                                </button>
                            </div>
        
                        </div>
                    </ng-container>

                        <div *ngFor="let campaign of campaignList" class="campaign-check-item" (click)="checkCampChange(campaign.id)">
                            <mat-checkbox [value]="campaign.id"   (change)="checkCampChange($event.source.value)" [checked]="checkedCampaignIds.includes(campaign.id)" [class]="'campaign-check-box'" mosType ></mat-checkbox>
                            <span>{{campaign.name}}</span>
                        </div>
            
                </div>
                <div class="section-split"></div>
            

                </mat-expansion-panel>
        </mat-accordion>
 
        <section class="inactive-camp">
            <mat-accordion class="home-expand-mat">
                <mat-expansion-panel 
                [expanded]="inactiveCampaignExpand"
                (opened)="inactiveCampaignExpand = true" (closed)="inactiveCampaignExpand = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="panel-title" mosType >
                                {{ 'inactive_campaigns' | translate }}({{inactiveCampList.length}})
                            </span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
 
    
                    <div class="campaigns-list" mosType>
                        <ng-container *ngIf="inactiveCampList.length > 0">
                            <div class="signup-btn-container">
                                <div class="refreshbtn-container" mosType>
                                    <button (click)="applyRefresh()" class="signup-form-btn">
                                        <span class="global-font-type">{{ 'refresh' | translate }}</span>
                                        <img  src="./assets/icons/home_refresh_camp.svg"/>
                                    </button>
                                </div>
                            </div>
                        </ng-container>

                            <div *ngFor="let campaign of inactiveCampList" class="campaign-check-item" (click)="checkCampChange(campaign.id)">
                                <mat-checkbox [value]="campaign.id"    (change)="checkCampChange($event.source.value)" [checked]="checkedCampaignIds.includes(campaign.id)" class="campaign-check-box" mosType></mat-checkbox>
                                <span>{{campaign.name}}</span>
                            </div>
                
                    </div>
                
                    <div class="section-split"></div>

                    </mat-expansion-panel>
            </mat-accordion>
        </section>

    </div>

    <div class="camp-data-footer">
        <div (click)="doPrint()" class="download-pdf-btn" mosType>

            <span>{{'download_pdf_v2'  | translate }}</span>

            <img src="./assets/icons/download_pdf.svg" />

        </div>

    </div>



</div>