import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PjSettingsService } from "src/app/sharedlib/pj-settings.service";
import { BizUserDto, BrandDto, RegisterStatus, UpdatePwdDto } from "./api-entities";


@Injectable()
export class ProfileApi {

    constructor(private httpClient: HttpClient,
        private pjsettings: PjSettingsService){

   }


    public updateProfile(data:BizUserDto):Observable<BizUserDto>{

        let url = this.pjsettings.getApiBase().concat("users/update-bizuser")

         return this.httpClient.post(url, data) as Observable<BizUserDto>
    }

    public updatePwd(pwdDto: UpdatePwdDto):Observable<RegisterStatus>{

        let url = this.pjsettings.getApiBase().concat("users/update-pwd")

        return this.httpClient.post(url, pwdDto) as Observable<RegisterStatus>
    }

    public getProfile(userId: any):Observable<BizUserDto>{

        let url = this.pjsettings.getApiBase().concat(`users/biz-user/${userId}`)

        return  this.httpClient.get(url) as  Observable<BizUserDto>

    }

    public getBrandInfo(brandId: any):Observable<BrandDto>{

    let url = this.pjsettings.getApiBase().concat(`users/brand/${brandId}`)
    return  this.httpClient.get(url) as  Observable<BrandDto>
    }

    public uploadProfileImage(userId: any,file: File){

        let formData = new FormData()
        formData.append("fileName", "mob_" + file.name)
        formData.append("file", file)


        //bizprofile
        let url = this.pjsettings.getApiBase().concat(`users/${userId}/bizprofile`)
        
        return this.httpClient.post(url, formData)

    }

    public uploadBrandImage(brandId: any,file: File){

        let formData = new FormData()
        formData.append("fileName", "mob_" + file.name)
        formData.append("file", file)


        //bizprofile/brand-logo/{brandId}
        let url = this.pjsettings.getApiBase().concat(`users/brand-logo/${brandId}`)
        
        return this.httpClient.post(url, formData)

    }


}