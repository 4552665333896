import { Injectable } from '@angular/core';
import { AuthUserDto, BrandDto } from './client/api-entities';

@Injectable({providedIn: 'root'})
export class SessionStorage {


    constructor(){}

    public getToken():string{
       let token:string = String(localStorage.getItem("pj_authtoken"))
    // let token:string = String(localStorage.getItem("pj_authtoken"))
       return token
    }
    public hasToken():boolean{
      let token = localStorage.getItem("pj_authtoken") 
     //let token = localStorage.getItem("pj_authtoken")   
       return token != undefined
    }

    public getBrandId():string {
       let brandId =  String(localStorage.getItem("pj_brandId"))
      //  let brandId =  String(localStorage.getItem("pj_brandId"))
        return brandId
    }

    public getUserId():any{

       let userId = localStorage.getItem("pj_user_id")

       return userId
    }
    public clearToken(){
        localStorage.removeItem('pj_authtoken')
        localStorage.clear()
    }
    public isAdmin():boolean{
       let raw = localStorage.getItem("pj_raw")
       return  raw != undefined && raw.includes("ROLE_ADMIN")
    }

    public setBrandid(brandid: any ){
      localStorage.setItem("pj_brandId",brandid)
    }
    public setBrandName(brandName: any ){
      localStorage.setItem("pj_brandName",brandName)
    }
    public saveAuthUser(authUser: AuthUserDto){
        localStorage.setItem("pj_raw", JSON.stringify(authUser))
        localStorage.setItem("pj_authtoken",`Bearer ${authUser.token}`)
        if(authUser.user != undefined){
         localStorage.setItem("pj_brandId",authUser.user.brandId)
         localStorage.setItem("pj_brandName",authUser.user.brandName)
         localStorage.setItem("pj_user_id",String(authUser.user.id))
        }

    }

    public getBrandName():string{
       let brandName = localStorage.getItem("pj_brandName") ?? ""
       let str = brandName

       if(str.includes("(")){
       
         str =   str.substring(0, str.lastIndexOf("("))
       }
       return str

    }
    public getBrand():BrandDto{
   
    let itemJson =     localStorage.getItem("pj_brand")
    
    let itemObject: any

    if(itemJson){
        itemObject = JSON.parse(itemJson)
    }
    return itemObject
    }

    public saveBrand(brandInfo: BrandDto){
        localStorage.setItem("pj_brand", JSON.stringify(brandInfo))
        localStorage.setItem("pj_brandId", brandInfo.id)
        localStorage.setItem("pj_brandName", brandInfo.name)
    }

}