import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map, Observable,observable, tap } from "rxjs";
import {  } from "rxjs/ajax";
import { PjalertService } from "../sharedlib/pjalert.service";
import { PjTranslateService } from "../sharedlib/pjtranslare.service";
import { AuthManager } from "./auth-manager";
import { SessionStorage } from "./session-storage";


@Injectable({
    providedIn: "root"
})
export class ApiAuthAdapter implements HttpInterceptor{

  public logoutProcess: boolean = false
    constructor(private sessionStorage: SessionStorage, 
      private authManager: AuthManager,
      private pjAlert: PjalertService,
      private pjTranslate: PjTranslateService,
      private router: Router){

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        

        /*

 .pipe(
        tap({
          // Succeeds when there is a response; ignore other events
          next: (event) => (ok = event instanceof HttpResponse ? 'succeeded' : ''),
          // Operation failed; error is an HttpErrorResponse
          error: (error) => (ok = 'failed')
        }),
        // Log when response observable either completes or errors
        finalize(() => {
          const elapsed = Date.now() - started;
          const msg = `${req.method} "${req.urlWithParams}"
             ${ok} in ${elapsed} ms.`;
          this.messenger.add(msg);
        })
      );

        */

        const authReq = req.clone({
            headers: req.headers.set('Authorization', this.sessionStorage.getToken())
          })

          return next.handle(authReq).pipe(tap({
            next: (e) => {
                   
             //   let res = (e instanceof HttpResponse)
          
            },
            error: (error) => {

              if( (error instanceof HttpErrorResponse)){
                let res = (error as HttpErrorResponse)
                if(res.status == HttpStatusCode.Forbidden && !this.logoutProcess ){
                  this.logoutProcess = true
                  this.pjAlert.show({message: this.pjTranslate.get('login_exapired') ,confirmCallback: () => {
                    this.authManager.doLogout()
                  }})
                }
    
              }
   
            }
          }));
    }
    
}