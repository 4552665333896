import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiAuthAdapter } from './api-authadapter';
import { SharedlibModule } from '../sharedlib/sharedlib.module';
import { PerformApi } from './client/perform-api';
import { AuthManager } from './auth-manager';
import { AuthApi } from './client/auth-api';
import { ProfileApi } from './client/profile-api';
import { CandApi } from './client/cand-api';




@NgModule({
  declarations: [
  
  ],
  exports: [
    
  ],
  providers:[
    CandApi,
    ProfileApi,
    PerformApi,
    AuthApi,
    ApiAuthAdapter,
    AuthManager
  ],
  imports: [
    SharedlibModule,
    CommonModule
  ]
})
export class ApiLibModule { }
