import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ApiAuthAdapter } from './api-lib/api-authadapter';
import { ApiLibModule } from './api-lib/api-lib.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LanguageSwitchModule } from './language-switch/language-switch.module';
import { StatsRouteReuseStrategy } from './statsroute-reuse';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [

    HttpClientModule,
    ApiLibModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LanguageSwitchModule
  ],
  providers: [
    [{ provide: HTTP_INTERCEPTORS, useClass: ApiAuthAdapter, multi: true },
      { provide: RouteReuseStrategy, useClass: StatsRouteReuseStrategy }
    ],
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
