import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, tap } from 'rxjs';
import { PjTranslateService } from '../sharedlib/pjtranslare.service';
import { RegisterStatus } from './client/api-entities';
import { AuthApi, GeoDb } from './client/auth-api';
import { PerformApi } from './client/perform-api';
import { SessionStorage } from './session-storage';

@Injectable({providedIn: 'root'})
export class AuthManager {


    constructor(private authApi: AuthApi,
        private router: Router,
        private tranlate: PjTranslateService,
      private  sessionStorage: SessionStorage){


    }

    public doLogin(username: string, pwd: string) : Observable<any>{
        return this.authApi.loginApi(username,pwd).pipe(tap((res) => {
            this.sessionStorage.saveAuthUser(res)
        }))
    }

    public doSignup(signupData: any):Observable<RegisterStatus>{
        return this.authApi.signupApi(signupData)
    }
    public doLogout(){
        this.sessionStorage.clearToken()
        this.tranlate.restoreDefaultLang()
        this.router.navigate(['/auth/login'],{replaceUrl: true})
        .then(() => {
          location.reload()
        })

    }
    public getGeoIp():Observable<GeoDb>{
       return this.authApi.getGeoInfo()
        .pipe(
            map((res:any) => {
                return {country: res.isoCode} as GeoDb
            }),
            catchError((err, caught) => {

            console.log("err:",err)
         return new Observable<GeoDb>((sub) => {
            sub.next({'country' : "CN"})
         })
        }))


    }
}
