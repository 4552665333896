var prodUrl = "https://corp.pjdaren.com/backend/api/v1/"
var contentCollector = "https://corp.pjdaren.com/collector/api/v1/"
var pdfPrinter = "https://corp.pjdaren.com/backendprinter/"
export const environment = {
  production: true,
  backendUrl: prodUrl,
  contentCollector: contentCollector,
  pdfPrinter: pdfPrinter,
  imgURL: 'https://pjdaren.oss-cn-shanghai.aliyuncs.com/wom/prod/',
};
