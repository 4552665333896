export const translateCn = {

  "please_select" : "请选择",



  "signup": "注册",
  "signin": "登录",
  "create_account": "创建账号",
  "first_name" : "名",
  "last_name" : "姓",
  "job_title" : "职位",
  "brand_agency_name" : "品牌/机构名称",
  "working_email" : "工作邮箱",
  "phone_number": "电话号码",
  "password": "密码",
  "how_hear" : "您从什么渠道知晓评价达人的？",
  "dont_have_account": "还没有账号？点此处注册",
  "remember_me": "记住此账号",
  "forgot": "忘记密码？",
  "accept_receive_news" : "我同意接收来自评价达人的新闻简讯和订阅推送。",

  "privacy_policy" : "隐私政策",
  "download_pdf_v2": "下载PDF",
  "download_candidates" : "下载",
  "active_campaigns" : "进行中的活动",
  "inactive_campaigns" : "已结束的活动",
  "total_numbers": "参与达人",
   "age_distribution": "年龄分布",
   "areal_distribution": "地理分布",
   "city_tier_distribution": "各级城市分布",
   "income_distribution": "收入分布",
   "social_media_app_users": "社交媒体使用率",
   "interest_distribution": "兴趣分布",
   "registration_completed" : "注册申请已提交！",
   "thank_register" : "感谢您注册使用评价达人！",

// candidates tab
"candidates": "候选人",
"profile_url" : "链接",
"suggested_creators" : "达人推荐",
"approved_creators" : "已通过",
"rejected_creators" : "已拒绝",

"suggested_candiates" : "推荐的达人数",
"checked_candiates" : "已查看候选人数",
"pending_candiates" : "待定候选人数",
"approved_candidates" : "已通过人数",
"rejected_candidates" : "已拒绝人数",
"enlarge_qr_code" : "点击二维码放大显示",

"followers" : "粉丝",
"total_eng" : "总互动量",
"categories" : "博主类型",
"act_approve" : "通过",
"act_reject" : "拒绝",

  "EMAIL_IS_TAKEN" : "这个电子邮件地址已经被占用了。",

  "from_17_to_24": "11",
  "from_25_to_34": "12",
  "from_35_to_44": "13",
  "11":"17 - 24",
  "12":"25 - 34",
  "13":"35 - 44",
  "14":"44 以上",
  "income_316": "低于 ¥3,999",
  "income_321": "¥4,000-¥7,999",
  "income_326": "¥8,000-¥11,999",
  "income_331": "¥12,000-¥15,999",
  "income_336": "¥16,000-¥19,999",
  "income_341": "¥20,000-¥24,999",
  "income_346": "¥25,000以上",
    "32": "健身达人",
    "37": "学生党",
    "35": "时尚辣妈",
    "38": "派对狂",
    "41": "宠物伴侣",
    "30": "美妆皇后",
    "36": "时尚健康",
    "34": "时尚达人",
    "42": "五星大厨",
    "39": "零食爱好者",
  "t1": "T1",
  "t2": "T2",
  "t3": "T3",
  "t4": "T4",
  "t5": "T5",
  "t6": "T6",
  "t7": "T7",
  "t8": "T8",
  "t9": "T9",
  "t10": "T10",
  "cancel": "取消",
  "CN-11": "北京",
  "CN-13": "河北省",
  "CN-14": "山西",
  "CN-15": "内蒙古",
  "CN-21": "辽宁省",
  "CN-22": "吉林省",
  "CN-23": "黑龙江",
  "CN-31": "上海",
  "CN-32": "江苏省",
  "CN-34": "安徽省",
  "CN-35": "福建省",
  "CN-36": "江西省",
  "CN-37": "山东省",
  "CN-41": "河南省",
  "CN-42": "湖北省",
  "CN-43": "湖南省",
  "CN-44": "广东",
  "CN-45": "广西",
  "CN-46": "海南",
  "CN-50": "重庆",
  "CN-51": "四川",
  "CN-52": "贵州",
  "CN-53": "云南",
  "CN-54": "西藏",
  "CN-61": "陕西",
  "CN-62": "甘肃",
  "CN-63": "青海",
  "CN-64": "宁夏",
  "CN-65": "新疆",
  "CN-12": "天津",
  "CN-71": "台湾省",
  "CN-33": "浙江省",
    "Single Choice": "单选题",
    "Multiple Choice" : "多选题",
    "Ranking" : "排序问题",
    "Rating" : "评分问题",
    "Intensity" : "强度问题",
    "Open Text" : "开放式问题",
    "Numeric Input" : "数字输入问题",
    "single_choice": "单选题",
    "multiple_choice" : "多选题",
    "ranking" : "排序问题",
    "rating" : "评分问题",
    "intensity" : "强度问题",
    "open_text" : "开放式问题",
    "numeric_input" : "数字输入问题",
  "footer-note": "评价达人 | Powered by Real Consumers",
  "na": "N/A",

  "achieved" : "选人完成率:",
  "target" : "目标人数:",
  "email": "邮箱",
  "estimated_eng" : "预计互动量",
  "total_engagement" :"总互动量",
  "accept_privacy_policy" : "请接受隐私政策和使用条款。",
  "user_profile": "参与者信息",
  "refresh": "加载数据",
  "members": "名创作者",
  "performance": "活动绩效",
  "sample_content": "内容示例",
  "insight": "参与者洞察",
  "total_creators": "参与创作者人数",
  "creators" : "创作者",
  "total_investment" : "总投资",
  "posts": "投放内容",
  "creators_by_platform" : "各平台创作者",
  "posts_by_platform" : "各平台投放内容",
  "overall_performance" : "整体绩效",
  "mpv_chart" : "MPV",
  "roi_chart" : "ROI",
  "daily_performance":"每日绩效",
  "post_by_day" : "每日发布量",
  "enage_by_day" : "每日互动量",
  "reach_by_day" : "每日曝光量",
  "performance_data" : "绩效数据",
  "platform_perfomance" : "平台绩效",
  // Performance Data


  "data_platform" : "平台",
  "number_creators" : "创作者数量",
  "post" : "内容投放量",
  "engagement":"互动量",
  "engage_rate" : "互动率",
  "mpv" : "MPV",
"investment" : "投资额",
"roi": "ROI",
"downlaod_links_platform": "下载绩效数据",
"creators_perform": "创作者绩效",

"creator_id": "创作者ID",
"followers_number": "粉丝数",
"data_er": "预计阅读/观看量",
"data_rp": "阅读/观看绩效",
"data_rp_excel": "阅读/观看绩效（%）",
"data_eng" : "互动量",
"data_ee" : "预计互动量",
"data_ee_excel" : "预计互动量",
"data_ep" : "互动绩效",
  "data_ep_excel" : "互动绩效（%）",
"data_eng_percent" : "互动率",
  "data_eng_excel" : "互动率（%）",
"resonance" : "共鸣力",
"resonance_excel" : "共鸣力（%）",
"likes_number" : "点赞",
"favorites_number" : "收藏",
"collections_number" : "收藏",
"repost_number" : "分享",
"comments_number" : "评论",
"shares_number" : "分享",
"nickname" : "名用户",
"link" : "Link",
"perfomance_overview" : "整体绩效",
"post_detail": "内容详情",
"read_more" : "...展开更多",
"hide" : "...收起",
"action_save" : "Save",
"sns_platform" : "平台",
"sns_reposts" : "分享",
"sns_collections" : "收藏",
// info signs
"total_eng_info"  : "总互动量：社交媒体帖子的点赞、评论、收藏和分享/转发的总和。",
"total_reach_info" : "总阅读/观看量：社交媒体帖子的所有阅读量或观看量的总和。",
"mpv_info" :"MPV:媒体绩效价值（MPV）基于付费社交媒体广告的成本来衡量KOLC活动的成本效益。",
"roi_info" :"ROI:(MPV-投资额)/投资额。根据广告价值与投资衡量KOLC营销活动效率。",
"eng_rate_info" :"互动率：（总互动量/总曝光量）*100。衡量受众与社交媒体帖子互动的指标。",
"estimated_reach_info" :"预计阅读/观看量：根据过去的表现数据，对社交媒体帖子的阅读或观看次数的预测。",

"estimated_eng_info" :"预计互动量：根据过去的表现数据，预测观众与社交媒体帖子的互动数据。",
"reach_perfomance_info" :"阅读/观看量绩效：(实际阅读观看/预计阅读观看)*100。衡量社交媒体帖子的实际阅读/观看量与其预计阅读/观看量对比绩效的指标。",
"eng_performance_info" :"互动量绩效：(实际互动量/预计互动量)*100。衡量社交媒体帖子的互动量与其预计互动量对比绩效的指标。",
"resonance_info" :"共鸣力：(总阅读观看/总粉丝数)*100。衡量社交媒体帖子与创作者关注度之间的影响。",
"creator_platform_info" : "各平台创作者：每个社交媒体平台上已开始创作内容中的创作者人数。",
"post_by_platform_info" : "各平台投放内容：每个社交媒体平台上已审核通过并完成发布的内容数量。",

"m_name" : "Name",
"perosnal_detail":  "个人信息",
"position_" : "职位",
"wechatId": "微信ID*",
"optional" : "(非必填项)",
"action_cancel" : "取消",
"up_save" : "保存",
"change_pwd" : "重置密码",
"current_pwd" : "当前密码",
"input_cur_pwd" : "输入当前使用的密码",
"new_pwd" : "新密码",
"input_new_pwd" : "输入新密码",
"confirm_pwd" : "再次输入新密码",
"my_profile": "我的主页",

// End candidates tab

// Pending Translate

//  contents sample page
"nav_content_sample": "内容",
"sort_by" : "排序方式:",
"sort_all" : "全部",
"sort_eng": "互动量",
"required_field": "此字段为必填项",

// login page

"already_have_account": "您已有帐户 ？",
//  Heard from

"Baidu" : "百度",
"Google" : "谷歌",
"Linkedin" : "领英",
"Wechat" : "微信公众号",
"recommended_by_friend" : "朋友/同事推荐",
"pub_blog" : "媒体/博客/报道",
"person_event" : "线下活动",
"Other" : "其他",

// Reset Pwd
"reset_pwd" : "重置密码",
"input_email_reset_pwd" :"输入与您的帐户关联的电子邮件，我们将发送验证码重置您的密码",
"ver_code" : "输入电子邮件里收到的验证码*",
"passwords_not_match" : "您输入的密码不匹配.",

// Candidate Reject dialog
"help_understand_creators" : "帮助我们更好地了解您的目标创作者！",
"feedback_important" : "你的反馈对我们后期推荐创作者时非常重要。请告诉我们拒绝此候选人的原因。",
"skip_btn" : "跳过",
"submit_btn" : "提交",
"cancel_btn" : "取消",

"edit_feedback" :"添加/编辑反馈",
"edit_feedback_info" :"你的反馈对我们后期推荐创作者时非常重要。请告诉我们拒绝此候选人的原因。",

// Alert dialog

"ok_btn" : "确认",
"failed_upload_img" :"上传图片失败",
"login_exapired" : "登录过期。请重新登录。",

"USER_NOT_FOUND" : "此用户不存在.",
"EMAIL_IS_INVALID" : "您输入的电子邮件地址无效。",
"VERIFICATION_CODE_STILL_VALID" : "验证码已发送。请在1分钟后重试。",
"VERIFICATION_CODE_INVALID" : "验证码错误.",
"VERIFICATION_CODE_EXPIRED" : "您输入的验证码已过期。",
"no_data":"暂时没有数据",
"select_one_campaign":"请选择一个活动以执行此部分。",

"terms_of_use" : "使用条款",
"and" : "和",
// By creating this account you accept our Terms of Use and Privacy Policy
"accept_pjdaren_privacy" : "创建此帐户即表示您接受我们的使用条款和隐私政策",
"email_id": "邮箱地址",

"reach":"阅读/观看量",
"Content":"内容",
"Nickname":"昵称",
"invalid_login":"电子邮箱地址或密码错误",
"customize_mpv": "添加自定义信息",
"customize_media_value": "自定义媒体影响力价值",
"Save": "保存",
"defaul_sns_rates": "默认社交影响率矩阵",
"Cancel":"取消",
"team_get_back":"评价达人团队正在审核您的注册申请，并将在两个工作日内与您联系。",
"contact_sales":`如果您需加快审核进度，请发送邮件至 <a class="signup link" href="mailto:sales@pjdaren.com">sales@pjdaren.com</a> 与评价达人取得联系。`,
"In the meantime, check out our Resource Centre": "在此期间，不如先来看看我们的资源中心",
"candidate_price": "报价",
  "price_yuan" : "报价(元)",
"est_eng_short":"预计互动量",
"est_reach_short":"预计阅读/观看量",
"estimated_reach":"预计阅读/观看量",
"engage_by_platform": "各平台互动量",
"total_reach" :"总阅读/观看量",
"rech_by_platform" : "各平台阅读/观看量",
  "cand_gender" : "性别" ,
  "excel_content" : "链接",
  "excel_post_date": "上映日期",
  "excel_angle": "分类",
"check_resource_center" : `在此期间，不如先来看看我们的 <a class="signup link bold" href="#">资源中心</a>`,
  "cand_remarks": "注"

}
