import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { PjTranslateService } from './pjtranslare.service';

@Directive({
  selector: '[appLangType]'
})
export class LangTypeDirective {

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2,
    translate: PjTranslateService
    ) {
      renderer.addClass(el.nativeElement, "lang-"+ translate.getIsoLang() );

 

     
 }

}
