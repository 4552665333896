import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PerformApi } from 'src/app/api-lib/client/perform-api';
import { SessionStorage } from 'src/app/api-lib/session-storage';
import { PjTranslateService } from 'src/app/sharedlib/pjtranslare.service';
import { PdfLoaderComponent } from '../pdf-loader/pdf-loader.component';

@Component({
  selector: 'app-campaigns-sidebar',
  templateUrl: './campaigns-sidebar.component.html',
  styleUrls: ['./campaigns-sidebar.component.scss']
})
export class CampaignsSidebarComponent implements OnInit {
  activeCampaignExpand: boolean = false
  inactiveCampaignExpand: boolean = false


  public campaignList: Array<any> = []
  public inactiveCampList: Array<any> = []
  public checkedCampaignIds:Array<any> = []
  constructor(public performApi: PerformApi,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public translate: PjTranslateService,
    public sessionStorage: SessionStorage
    ) {

    }

  ngOnInit(): void {


    this.loadActiveCapmains()
  }
  loadActiveCapmains(){
    let queryCampaigns:Array<string> = this.route.snapshot.queryParamMap.getAll('cid') ?? []

    if(this.sessionStorage.getBrandId() == undefined){
      return
    }

    this.performApi.getCampaignsList(this.sessionStorage.getBrandId()).subscribe((res) => {
        this.campaignList = res.active
        this.inactiveCampList = res.inactive

        this.checkedCampaignIds = res.all.filter((m:any) => queryCampaigns.includes(String(m.id)) ).map((m:any) => m.id)
        this.inactiveCampaignExpand = this.inactiveCampList.some((v:any) =>       this.checkedCampaignIds.includes(v.id))

        if(this.checkedCampaignIds.length == 0 && res.active.length > 0){
          this.checkedCampaignIds = [res.active[0].id]
          this.applyRefresh()
        }
        this.activeCampaignExpand = true

    })


  }

  checkCampChange(campId:any){

    let cmpClone:Array<any> =  this.checkedCampaignIds

    if(this.checkedCampaignIds.includes(campId)){
        cmpClone =  this.checkedCampaignIds.filter((value => {
        return value != campId
      }))

    }else{
       cmpClone.push(campId)
    }
    this.checkedCampaignIds =  cmpClone
  }

  doPrint(){

    const dialogRef = this.dialog.open(PdfLoaderComponent, {
      width: '350px',
      height: '40px',
      panelClass: 'pdf-loader-dialog',
      disableClose: true,
      data: {campaignIds: this.checkedCampaignIds},
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log("afterClosed:", result)



    });

  }

  public get logoLang():string{

   let lang =  this.translate.getIsoLang()

    return `logo-${lang}`
  }
  applyRefresh(){

    let refreshUrl = "./"
//./candidates/list
    if(this.router.url.includes("campaign")){
       refreshUrl = "./stats/campaign"
    }
    if(this.router.url.includes("members")){
       refreshUrl = "./stats/members"
    }
    if(this.router.url.includes("content")){
      refreshUrl = "./stats/content"
   }
   if(this.router.url.includes("question-insights")){
      refreshUrl = "./stats/question-insights"
   }
   if(this.router.url.includes("candidates")){
    refreshUrl = "./candidates"
   }

   if(refreshUrl.includes("candidates") &&  this.checkedCampaignIds.length > 1){
    this.router.navigate(["./stats/members"], {queryParams: {cid: this.checkedCampaignIds}})
   }else{
    this.router.navigate([refreshUrl], {queryParams: {cid: this.checkedCampaignIds}})
   }


  }

}
