<div  class="home-nav-container">
    <nav appLangType class="campaign-nav items-stretch flex-nowrap">
        <button *ngIf="canNavigate" class="nav-item w-auto" routerLinkActive="active-nav" [queryParams]="queryParams"  routerLink="./candidates">
            <span >
                {{ 'candidates' | translate }}
            </span>
        </button>

        <button *ngIf="!canNavigate"  (click)="candidatesClickDisabled()" class="nav-item w-auto" >
            <span >
                {{ 'candidates' | translate }}
            </span>
        </button>


        <button class="nav-item w-auto" routerLinkActive="active-nav" [queryParams]="queryParams"  routerLink="./stats/members">
            <span >
                {{ 'user_profile' | translate }}
            </span>
        </button>

        <button class="nav-item w-auto" routerLinkActive="active-nav" [queryParams]="queryParams"   routerLink="./stats/campaign" >
            <span >
                {{ 'performance' | translate }}
            </span>
        </button>

        <button class="nav-item w-auto" routerLinkActive="active-nav" [queryParams]="queryParams"  routerLink="./stats/content">
            <span >
                {{ 'nav_content_sample' | translate }}
            </span>
        </button>

        <button class="nav-item w-auto" routerLinkActive="active-nav" [queryParams]="queryParams"  routerLink="./stats/question-insights" >
            <span >
                {{ 'insight' | translate }}
            </span>
        </button>

        <button class="nav-item w-auto" *ngIf="isAdmin" routerLinkActive="active-nav" [queryParams]="queryParams"  routerLink="./admin" >
            <span >
                Admin
            </span>
        </button>
    </nav>
    <div class="profile-nav-wrap">
        <nav>
            <div class="lang-switcher">
                <app-language-switch [theme]="'dark'"> </app-language-switch>
            </div>

            <div class="split-line">

            </div>
            <!--
            <button [queryParams]="queryParams" class="profile-btn" routerLinkActive="active-nav"  routerLink="./stats/profile" >
                <div class="my-profile-wrap">
                    <div *ngIf="!profileImage" class="profile-icon">

                    </div>
                    <div  *ngIf="profileImage" class="brand-logo">
                        <img [src]="profileImage | pjimgurl"/>
                    </div>
                    <span *ngIf="brandName">
                        {{brandName}}
                    </span>
                </div>
            </button>

            -->


            <div  class="profile-btn">
                <div class="my-profile-wrap" [matMenuTriggerFor]="profileMenu">
                    <div *ngIf="!profileImage" class="profile-icon">

                    </div>
                    <div  *ngIf="profileImage" class="brand-logo">
                        <img [src]="profileImage | pjimgurl"/>
                    </div>
                    <span *ngIf="brandName">
                        {{brandName}}
                    </span>
                </div>

                <mat-menu #profileMenu="matMenu" class="profileMenu">
                    <app-profile-dropdown></app-profile-dropdown>
                </mat-menu>
            </div>




        </nav>

    </div>
</div>
