import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface PjAlertParams{
  title?: string,
  message?: string,
  hideConfirm?: boolean,
  showCancel?: boolean,
  confirmCallback?: Function 
  cancelCallback?:  Function
}
@Component({
  selector: 'app-pjalert-diaolg',
  templateUrl: './pjalert-diaolg.component.html',
  styleUrls: ['./pjalert-diaolg.component.scss']
})
export class PjalertDiaolgComponent {


  constructor(
    public dialogRef: MatDialogRef<PjalertDiaolgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PjAlertParams ) {

  }

  public onCloseClick(){

    this.dialogRef.close()
  }

  public onConfirm(){
    if(this.data.confirmCallback){
      this.data.confirmCallback()
    }
    this.dialogRef.close()
  }
  public onCancel(){
    if(this.data.cancelCallback){
      this.data.cancelCallback()
    }
    this.dialogRef.close()

  }
}
