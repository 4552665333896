import { Component } from '@angular/core';
import { LangManagerService } from './language-switch/lang-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'brand-dashboard22';

  constructor( ){
 
  }
}
