import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PjSettingsService } from "src/app/sharedlib/pj-settings.service";
import { ApplySnsRatesDto, CreatorsPerformDto, LinkContentDto, PagedResponse, QuestionDto, SnsEntity, SnsPlatformDto } from "./api-entities";


@Injectable()
export class PerformApi {

    constructor(private httpClient: HttpClient, private pjsettings: PjSettingsService){

    }

    public getCampaignsList(brandId: string):Observable<any>{

        let url  = this.pjsettings.getApiBase().concat(`brandReports/brand/${brandId}/campaign/list`)

       return this.httpClient.get(url)
    }
    public getMemberCount(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/campaign/membersCount")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }


    public ageDistribution(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/ageDistribution")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }

    public usersByProvinces(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/usersByProvinces")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }
    public usersCityTier(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/usersPerCityTier")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }

    public incomeLevels(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/incomeLevels")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }



    public userInterest(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/useInterest")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }


    public mediaUsage(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/userImpactPerSns")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }


    public investmentPerPlatform(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/investmentPerPlatform")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }

    public creatorsPerPlatform(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/creatorsPerPlatform")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }


    public postsPerPlatform(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/postsPerPlatform")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }


    public challengePerfomance(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/challengesPerformance")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }


    public mpvPerPlatform(camaigns: Array<any>):Observable<Object>{
        
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/mpvPerPlatform")

       return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }


    public snsRatesForCampaigns(camaigns: Array<any>):Observable<Object>{

        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/snsRates")

        return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }
    


    public defaultSnsRates(camaigns: Array<any>):Observable<Object>{

        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/defaultSnsRatesForCampaigns")

        return this.httpClient.get(url,{params: {"campaigns": camaigns}})
    }
    
    
    public applySnsRates(data: ApplySnsRatesDto){
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/applySnsRates")

        return this.httpClient.post(url,data)

    }
    public getCampaignSns(camaigns: Array<any>):Observable<Array<SnsEntity>>{
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/campaignSns")

        return this.httpClient.get(url,{params: {"campaigns": camaigns}}) as Observable<Array<SnsEntity>>

    }
    
    public getDailySnsPerformance(camaigns: Array<any>, snsId: any):Observable<any>{
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/dailySnsPerformance")

        return this.httpClient.get(url,{params: {"campaigns": camaigns, "snsId": snsId}})

    }

    public snsPlatformPerformance(camaigns: Array<any>):Observable<Array<SnsPlatformDto>>{
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/snsPlatformPerformance")

        return this.httpClient.get(url,{params: {"campaigns": camaigns}}) as Observable<Array<SnsPlatformDto>>

    }

    public creatorsPerformance(camaigns: Array<any>,snsId: any):Observable<Array<CreatorsPerformDto>>{
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/creatorsPerformance")

        return this.httpClient.get(url,{params: {"campaigns": camaigns, "snsId": snsId}}) as Observable<Array<CreatorsPerformDto>>

    }
    public contentLinks(camaigns: Array<any>,snsId: any):Observable<Array<CreatorsPerformDto>>{
        let url  = this.pjsettings.getApiBase().concat("brandReports/brand/campaign/contentLinks")

        return this.httpClient.get(url,{params: {"campaigns": camaigns, "snsId": snsId}}) as Observable<Array<CreatorsPerformDto>>
    }
    public contentDetail(urlId: any):Observable<LinkContentDto>{
        let url  = this.pjsettings.getCollectorApi().concat(`instance-report/get-content/${urlId}`)

        return this.httpClient.get(url) as Observable<LinkContentDto>
    }

    public questionsList(camaigns: Array<any>, pageNo: number, size: number):Observable<PagedResponse<QuestionDto>>{
        let url  = this.pjsettings.getApiBase().concat("questionInsights")

        return this.httpClient.get(url,{params: {"campaigns": camaigns, "pageNo": pageNo, "size": size}}) as Observable<PagedResponse<QuestionDto>>
    }

    

    public downloadPdf(camaigns: Array<any>,brandId: any, lang:string):Observable<any>{
        let url  = this.pjsettings.getPdfPrinter()

        return this.httpClient.get(url, {params: {"cid": camaigns,brandId: brandId, "lang": lang},
        reportProgress: true,
        responseType:  "blob"})

    }
}
