import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  MatMenuModule } from '@angular/material/menu';
import { LanguageSwitchComponent } from './language-switch.component';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';



@NgModule({
  declarations: [
    LanguageSwitchComponent
  ],
  providers: [
    Overlay
  ],
  exports: [
    LanguageSwitchComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    OverlayModule
  ]
})
export class LanguageSwitchModule { }
