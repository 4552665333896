import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PjSettingsService } from "src/app/sharedlib/pj-settings.service";
import { AuthUserDto, BizUserDto, RegisterStatus } from "./api-entities";


/*

//ipapi
{
"ip": "103.142.140.9",
    "network": "103.142.140.0/24",
    "version": "IPv4",
    "city": "Singapore",
    "region": null,
    "region_code": null,
    "country": "SG",
    "country_name": "Singapore",
    "country_code": "SG",
    "country_code_iso3": "SGP",
    "country_capital": "Singapore",
    "country_tld": ".sg",
    "continent_code": "AS",
    "in_eu": false,
    "postal": null,
    "latitude": null,
    "longitude": null,
    "timezone": null,
    "utc_offset": null,
    "country_calling_code": "+65",
    "currency": "SGD",
    "currency_name": "Dollar",
    "languages": "cmn,en-SG,ms-SG,ta-SG,zh-SG",
    "country_area": 692.7,
    "country_population": 5638676,
    "asn": "AS41378",
    "org": "Kirino LLC"
}
*/
// ipinfo
/*
  "ip": "58.33.109.2",
  "hostname": "2.109.33.58.broad.xw.sh.dynamic.163data.com.cn",
  "city": "Shanghai",
  "region": "Shanghai",
  "country": "CN",
  "loc": "31.2222,121.4581",
  "org": "AS4812 China Telecom (Group)",
  "timezone": "Asia/Shanghai"
}
*/
export interface GeoDb{
    country: string,
    
}
export interface RequestResetPwdDto{
    email: string,
    lang: string
}
export interface ResetPwdDto{
    email: string,
    password: string,
    code: string

}
@Injectable()
export class AuthApi {

    constructor(private httpClient: HttpClient,
         private pjsettings: PjSettingsService){

    }

    public loginApi(username:string, pwd: string):Observable<AuthUserDto>{
        
        let url  = this.pjsettings.getApiBase().concat("users/login_cpanel")

            
        return this.httpClient.post(url, {username: username, password: pwd, rememberMe: true}) as Observable<AuthUserDto>    
    }

    public signupApi(data:any):Observable<RegisterStatus>{
        let url  = this.pjsettings.getApiBase().concat("users/register-trial")

        return this.httpClient.post(url, data) as Observable<RegisterStatus>
    }


    public requestResetPwdCode(resetDto: RequestResetPwdDto):Observable<any>{

        let url  = this.pjsettings.getApiBase().concat("users/email-code")

            
        return this.httpClient.post(url, resetDto) as Observable<any> 
    }
    public resetPwd(resetDto: ResetPwdDto):Observable<any>{

        let url  = this.pjsettings.getApiBase().concat("users/resetEmailPassword")
            
        return this.httpClient.post(url, resetDto) as Observable<any> 
    }

    public getGeoInfo():Observable<GeoDb>{
    
       let url = this.pjsettings.getApiBase().concat("geolocation")
            
        return this.httpClient.get(url,{}) as Observable<any>
    }
}