import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorage } from './api-lib/session-storage';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate{


  constructor(private router: Router, private sessionStorage: SessionStorage ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (this.sessionStorage.hasToken()) {
        // logged in so return true
        return true;
    }
  
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login']).then(() => {
      location.reload()
    });
    return false;
  }


}
