import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home/home-page/home-page.component';
import { LoginGuard } from './login.guard';
const routes: Routes = [

    {
      path: 'auth',
      loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },

    {
      path: 'print',
      loadChildren: () => import('./printer/printer.module').then(m => m.PrinterModule)
    },
      // home route protected by auth guard


      { path: '', component: HomePageComponent,
      loadChildren: () =>  import('./home/home.module').then(m => m.HomeModule),
      canActivate: [LoginGuard] },
      { path: '**', redirectTo: '' }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
