import { Injectable } from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import { StyleUtilService } from './style-util.service';

@Injectable({
  providedIn: 'root'
})
export class PjplatformUtilService {

  public osInfo:string = ""

  constructor(

    private platform: Platform
  ) { }

  // https://stackoverflow.com/a/4900484/1163224

   getChromeVersion () {     
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
  }

  public is86Chrome(){


    if(this.platform.BLINK && this.getChromeVersion() <= 86){
      return true
    }

    return false

  }

  public getOSString() :string{

    if(this.osInfo.length > 0){
      return this.osInfo
    }
    let navigator:any =  window.navigator
    var userAgent = window.navigator.userAgent,
        platform = navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K','macOS'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = "";
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'MacOS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }

    this.osInfo = os.toLowerCase()
  
    return  this.osInfo;
  }

  public isWinFirefox(){
    let os = this.getOSString()
    if(os.toLocaleLowerCase().startsWith("windows") && this.platform.FIREFOX){
      return true
    }
    return false
  }

  public isChrome(){
    let os = this.getOSString()
    if(os.toLocaleLowerCase().startsWith("windows") && this.platform.FIREFOX){
      return true
    }
    return false
  }

  public isWinOs(){
    let os = this.getOSString()
    if(os.toLocaleLowerCase().startsWith("windows")){
      return true
    }
    return false
  }
}
