import { Injectable } from '@angular/core';
import {translateCn} from './translate/cn'
import {translateEn} from './translate/en'

export  enum  PjLangs {
  zhCn = "zh-cn",
  enUs = "en-us"
}

@Injectable({
  providedIn: 'root'
})
export class PjTranslateService {

  private  supportedLangs:Array<string> = [String(PjLangs.zhCn), String(PjLangs.enUs)]

  private  defaultLang: PjLangs = PjLangs.zhCn

  constructor() {}

  public get(key: string):string {

    let mkey: string = key

   let lang = this.getCurrentLang()

   if(lang == PjLangs.zhCn)
   {
    let transAny:any =  translateCn
    return  transAny[mkey] ?? key
   }else{
    let transAny:any =  translateEn
    return  transAny[mkey] ?? key

   }
  }
  public  setLang(lang: string){
    localStorage.setItem('lang',lang)
  }
  public restoreDefaultLang(){

  localStorage.setItem('lang',this.defaultLang)
  }
  public getCurrentLang():string{

    let lang = localStorage.getItem("lang") ?? ""

    if (!this.supportedLangs.includes(lang)){
      localStorage.setItem("lang",String(this.defaultLang))
      lang = this.defaultLang
    }

    return lang
  }
  public getIsoLang():string{

    let lang = this.getCurrentLang()


    return lang
  }


}
