import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-campaign-stats-page',
  templateUrl: './campaign-stats-page.component.html',
  styleUrls: ['./campaign-stats-page.component.scss']
})
export class CampaignStatsPageComponent implements OnInit {

  constructor() { 
    
 
  }

  ngOnInit(): void {
 

  }

 
  

}
