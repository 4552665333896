<div class="post-content d-flex flex-column">

    <div class="d-flex flex-column">
        <div *ngIf="data.title" class="title-view">
            <span>{{data.title}}</span>
        </div>

        <div *ngIf="data.message" class="descr-container" mosType> 
            <span>{{data.message}}</span>
        </div>
        <div class="w-100 d-flex justify-content-center">
            <button  *ngIf="!data.hideConfirm" (click)="onConfirm()" class="save-btn action-btn" type="button">
                <span class="global-font-type">{{ 'ok_btn' | translate }}</span>
            </button>

            <button *ngIf="data.showCancel" (click)="onCancel()" class="cancelBtn action-btn" type="button">
                <span class="global-font-type">{{ 'cancel_btn' | translate }}</span>
            </button>
        </div>
    </div>

</div>